import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/MDXPage/MDXPage.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hi! I'm `}<strong parentName="p">{`Sam Rose`}</strong>{`, a Senior Front End Developer working with the talented folks at `}<a parentName="p" {...{
        "href": "https://shopify.com"
      }}>{`Shopify`}</a>{` where we are making commerce better for `}<em parentName="p">{`everyone`}</em>{`.`}</p>
    <p>{`Before Shopfiy, I had the pleasure of working with the wonderful people at `}<a parentName="p" {...{
        "href": "https://indeed.com"
      }}>{`Indeed`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.ibm.com/"
      }}>{`IBM`}</a>{`, `}<a parentName="p" {...{
        "href": "https://about.gitlab.com/"
      }}>{`GitLab`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.cadre5.com/"
      }}>{`Cadre5`}</a>{`.`}</p>
    <p>{`I am currently traveling around the US most likely where there are mountains to climb and nature to explore.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      